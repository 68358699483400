<template>
    <section class="search-parameters">
       <div  v-for="(val, index) of optionparameter">
          <fieldset>
             <div class="panel panel-default col-12 bg-gray-light">
                <div class="row">
                   <div class="col-12 col-lg-2 col-md-3">
                      <div class="floating-label bg-white">
                         <select>
                            <option value="anywhere" selected="selected">anywhere</option>
                            <option value="start">start</option>
                            <option value="end">end</option>
                            <option value="entire">entire</option>
                         </select>
                         <label>Search where</label>
                      </div>
                   </div>
                   <div class="col-12 col-lg-4 col-md-3">
                      <div class="floating-label bg-white">
                         <select class="infield">
                            <option value="subject">-- Subject --</option>
                            <option value="all">-- In Topic --</option>
                            <option value="ID">-- Interaction ID --</option>
                            <option value="login" selected="selected">-- Contact Login --</option>
                            <option value="loginbounced">-- Contact ID --</option>
                            <option value="Tags">-- Tags --</option>
                            <option value="segment">-- Segment Membership --</option>
                            <option value="30">Additional Detail</option>
                            <option value="33">Address1</option>
                            <option value="34">Address2</option>
                            <option value="35">City</option>
                            <option value="7">Company</option>
                            <option value="38">Country</option>
                            <option value="6">Customer Type</option>
                            <option value="29">Date</option>
                            <option value="31">Existing Phone Property</option>
                            <option value="1">First Name</option>
                            <option value="8">Job Title</option>
                            <option value="3">Last Name</option>
                            <option value="2">Middle Initial</option>
                            <option value="4">Phone</option>
                            <option value="37">Postal Code</option>
                            <option value="26">s2only</option>
                            <option value="36">State</option>
                            <option value="32">Svc Level 5 Min</option>
                            <option value="25">Text</option>
                         </select>
                         <label>In field</label>
                      </div>
                   </div>
                   
                   <div class="col-12 col-md-6 search-field">
                      <div class="floating-label bg-white">
                         <input type="text" class="searchtag search" data-role="tagsinput" style="display:block;">
                      
                         <label id="search" class="floatlabel">Search for</label>
                      </div>
                      <button class="btn btn-lg btn-link" type="button" title="Remove Field" @click="removeValue(index)">
                      <i class="fa fa-times-circle text-red"></i>
                      </button>
                   </div>
                </div>
    
                <button type="button" class="btn btn-link" v-if="spliton && index == 0 " >Or</button>
                
                <div class="row" v-if="spliton && index == 0">
                   <div class="col-12 col-lg-2 col-md-3">
                      <div class="floating-label bg-white">
                         <select>
                            <option value="anywhere" selected="selected">anywhere</option>
                            <option value="start">start</option>
                            <option value="end">end</option>
                            <option value="entire">entire</option>
                         </select>
                         <label>Search where</label>
                      </div>
                   </div>
                   <div class="col-12 col-lg-3 col-md-3">
                      <div class="floating-label bg-white">
                         <select class="infield">
                            <option value="subject">-- Subject --</option>
                            <option value="all">-- In Topic --</option>
                            <option value="ID">-- Interaction ID --</option>
                            <option value="login" selected="selected">-- Contact Login --</option>
                            <option value="loginbounced">-- Contact ID --</option>
                            <option value="Tags">-- Tags --</option>
                            <option value="segment">-- Segment Membership --</option>
                            <option value="30">Additional Detail</option>
                            <option value="33">Address1</option>
                            <option value="34">Address2</option>
                            <option value="35">City</option>
                            <option value="7">Company</option>
                            <option value="38">Country</option>
                            <option value="6">Customer Type</option>
                            <option value="29">Date</option>
                            <option value="31">Existing Phone Property</option>
                            <option value="1">First Name</option>
                            <option value="8">Job Title</option>
                            <option value="3">Last Name</option>
                            <option value="2">Middle Initial</option>
                            <option value="4">Phone</option>
                            <option value="37">Postal Code</option>
                            <option value="26">s2only</option>
                            <option value="36">State</option>
                            <option value="32">Svc Level 5 Min</option>
                            <option value="25">Text</option>
                         </select>
                         <label>In field</label>
                      </div>
                   </div>
                   <div class="col-12 col-md-3">
                      <div class="floating-label bg-white">
                         <select id="searchOption">
                            <option value="Contain">Contains</option>
                            <option value="Does Not Contain">Does Not Contain</option>
                            <option value="Is Greater Than">Is Greater Than</option>
                            <option value="Is Equal To">Is Equal To</option>
                            <option value="Is Less Than">Is Less Than</option>
                         </select>
                         <label>Search options</label>
                      </div>
                   </div>
                   <div class="col-12 col-md-4 search-field">
                      <div class="floating-label bg-white">
                         <input type="text" class="searchtag search" data-role="tagsinput" style="display:block;">
                      
                         <label id="search" class="floatlabel">Search for</label>
                      </div>
                      <button class="btn btn-lg btn-link" type="button" title="Remove Field" @click="removeValue(index)">
                      <i class="fa fa-times-circle text-red"></i>
                      </button>
                   </div>
                </div>
    
             </div>
          </fieldset>
          <div class="text-left mbottom-15 px-2" v-if="searchlength > (index + 1)">
            <button type="button" class="btn btn-link"  v-on:click="splitGroup(1)"> And </button>
          </div>
       </div>
       <div class="row">
          <button class="btn btn-lg btn-link" type="button" @click="addValue">
          <i class="fa fa-plus-circle text-light-blue"></i>
            Add another search parameter
          </button>
       </div>
    </section>
    </template>
    
    <script>
    
    export default {
      name: 'Interaction Search Parameter',
      components: {
        
      },
      data() {
          return{
              optionparameter : [1],
              searchlength : 1,
              spliton : false
            
          }
      },
      methods: {
       addValue() {
          this.optionparameter.push('')
          this.searchlength = parseInt(this.searchlength) + 1;
       },
       removeValue(index) {
          if(this.optionparameter.length > 1){
           this.optionparameter.splice(index, 1);
           this.searchlength = parseInt(this.searchlength) - 1;
          }
        },
        splitGroup(){
           this.spliton = true
        }
      }
      
    }
    </script>