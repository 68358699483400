<template>
   <small v-bind:class="btnclass">Resolved
   
   </small>
</template>

<script>

export default {
  name: 'Status Button',
  components: {

  },
 props:{},
 data() {
      return{
          row1 : false
      }
  },
  methods: {
    toggleRow(i) {
        this['row'+i] = !this['row'+i] 
    }
  }
  
}
</script>