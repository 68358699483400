<template>
    <table class="table table-striped table-hover">
       <thead>
          <tr role="row">
            <th></th>
            <th class="sorting">
                Name
            </th>
            <th class="sorting">
                First Name
            </th>
            <th class="sorting">
                Last Name 
            </th>
            <th class="sorting sorting_asc">
                Email
            </th>
            <th class="sorting sorting_desc">
                Company
            </th>
            <th class="sorting">
                Phone (Work)
            </th>
          </tr>
       </thead>
       <tbody>
         <tr role="row">
            <td name="name" class="sorting_1">
                <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                    <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                    <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
                </button>
            </td>
            <td>
                John Doe
            </td>
            <td>
                John
            </td>
            <td>
               Doe
            </td>
            <td>
                john.doe@example.com
            </td>
            <td>
                Example Company, Inc.
            </td>
            <td>
                312-555-5555
            </td>
          </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
                <td class="white-bg">&nbsp;</td>
                <td colspan="6">
                <SearchContactViewMode v-if="SearchContactViewMode" showGetThis = "true"></SearchContactViewMode>
   
                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                
                </td> 
            </tr>
            <tr role="row">
                    <td name="name" class="sorting_1">
                        <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                            <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                            <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                        </button>
                    </td>
                    <td>
                        Albert Metricman
                    </td>
                    <td>
                        Albert
                    </td>
                    <td>
                        Metricman
                    </td>
                    <td>
                        albert.metricman@example.com
                    </td>
                    <td>
                        Example Company, Inc.
                    </td>
                    <td>
                        312-555-5555
                    </td>
                    
            </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
                <td class="white-bg">&nbsp;</td>
                <td colspan="9">
                    <SearchContactViewMode v-if="SearchContactViewMode" showGetThis = "true"></SearchContactViewMode>

                    <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>
                    <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>
                    <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                </td> 
            </tr>
          
       </tbody>
    </table>
    </template>
    
    <script>
    import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
    import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
    
    import SearchContactViewMode from '../components/SearchContactViewMode.vue';
    import CreateNoteContent from '../components/CreateNoteContent.vue';
    import AgentEmailContent from '../components/AgentEmailContent.vue';
    import CreateTicketContent from '../components/CreateTicketContent.vue';
    import ForwardContent from '../components/ForwardContent.vue';
    import GetThisContent from '../components/GetThisContent.vue';
    
    import CaseProperty from '../components/CaseProperty.vue';
    import StatusButton from '../components/StatusButton.vue';
    
    
    import Multiselect from '@vueform/multiselect'
    
        export default {
          name: 'Search Result',
          props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
          components: {
            Multiselect,
            InteractionPropertyTab,
            DetailsandattachmentsTabContent,
            SearchContactViewMode,
            CreateNoteContent,
            AgentEmailContent,
            CreateTicketContent,
            ForwardContent,
            GetThisContent,
            CaseProperty,
            StatusButton
          },
          data: function(){
              return{
                showBCC : true,
                date: new Date(),
                timezone: '',
                showexpanded1: false,
                SearchContactViewMode : true,
                createnotecontent : false,
                agentemailcontent : false,
                createticketcontent : false,
                forwardcontent  : false,
                getthiscontent : false,
                replyType : this.replyType,
                showGetThis : this.showGetThis,
                statusbtntext:this.statusBtnText, 
                statusbtnclass:this.statusBtnClass, 
                showstatusbtnicon:this.showstatusBtnIcon,
                statusbtniconclass:this.statusBtnIconClass, 
                typebtntext:this.typeBtnText, 
                typebtnclass:this.typeBtnClass,
                showtypebtnicon:this.showtypeBtnIcon, 
                typebtniconclass:this.typeBtnIconClass,
                typebtniconclassgroup: this.typeBtnIconClassGroup,
                typegroupicon : this.typeGroupIcon,
                showexpanded2 : false
              }
          },
          mounted(){
               
               this.emitter.on("show-view-details", Data => {
                  this[Data.displaySegment] = true;
                  this.SearchContactViewMode = false;
                });
                this.emitter.on("hide-view-details", Data => {
                  this[Data.displaySegment] = false;
                  this.SearchContactViewMode = true;
                });
          }
        }
    </script>