<template>
    <div class="row">
       <div class="col-md-12">
           <button class="btn btn-primary bg-aqua-active btn-info margin-r-5" v-tooltip="{text: 'Send new agent email to John Doe',theme: {placement: 'bottom', },displayArrow : true }" v-on:click="show('agentemailcontent')">
               <i class="fa fa-envelope"></i>
               Agent email
           </button> 
           <button class="btn  btn-success margin-r-5" v-tooltip="{text: 'Add note to John Doe',theme: {placement: 'bottom', }}"  v-on:click="show('createnotecontent')">
           <i class="fa fa-pencil-square-o"></i>
           Note
           </button>
           <button class="btn  btn-warning margin-r-5" v-tooltip="{text: 'Create Ticket for John Doe',theme: {placement: 'bottom', }}"  v-on:click="show('createticketcontent')">
           <i class="fa fa-ticket"></i>
           Ticket
           </button>
       </div>
   </div>
   </template>
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
   import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
   
   
   import StatusAudit from '../components/StatusAudit.vue';
   import FullCaseHistory from '../components/FullCaseHistory.vue';
   import Toggle from '@vueform/toggle'
   
   import Multiselect from '@vueform/multiselect'
   
       export default {
         name: 'Expanded Row',
         props: {showGetThis:Boolean,showAnswer:Boolean},
         components: {
           Multiselect,
           InteractionPropertyTab,
           DetailsandattachmentsTabContent,
           StatusAudit,
           FullCaseHistory,
           Toggle
           
         },
         mounted(){
              if(this.showGetThis == 0){
                  this.showget = false;
              }else{
                 this.showget = true;
              }
   
               if(this.showAnswer == 0){
                  this.showanswer = false;
              }else{
                 this.showanswer = true;
              }
           },
         data: function(){
             return{
               showBCC : true,
               date: new Date(),
               timezone: '',
               showexpanded1: false,
               toggleMore : true,
               statusaudit : false,
               fullcasehistory : false,
               palintext: true,
               showget : Boolean(this.showGetThis),
               showanswer : Boolean(this.showGetThis),
               showconfirmdelete:false
             }
         },
          methods: {
               show(segment){
                 this.emitter.emit("show-view-details",{displaySegment:segment});
               }
               
           }
       }
   </script>
   
   